import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { PAGE } from 'utils/pages'

const FooterV2 = ({ locale }) => {
  const { t: navTranslations } = useTranslation('nav')

  const HEADER_MENU = [
    { text: navTranslations('nav_home'), link: PAGE.landing },
    {
      text: navTranslations('nav_wbso_application'),
      link: PAGE.wbsoApplication,
    },
    {
      text: navTranslations('nav_how_it_works'),
      link: `${PAGE.landing}#how-it-works`,
    },
    {
      text: 'Case Study',
      link: `${PAGE.landing}#case-study`,
    },
    { text: navTranslations('nav_demo'), link: `${PAGE.landing}#demo` },
    { text: navTranslations('nav_pricing'), link: `${PAGE.landing}#pricing` },
    { text: navTranslations('nav_blog'), link: PAGE.blog },
    {
      text: navTranslations('nav_wbso_knowledge_base'),
      link: locale === 'en' ? '/en/wbso-knowledge-base' : '/nl/wbso-kennisbank',
    },
  ]

  return (
    <section className="font-sfui w-full flex flex-col items-center pt-12 pb-8 px-6 gap-y-8 bg-g400 text-white lg:px-[100px]">
      <div className="w-full flex flex-col gap-y-12 md:flex-row md:gap-x-[200px]">
        <div className="flex flex-col">
          <span className="font-extrabold text-[32px] leading-none">
            Traqqie
          </span>
        </div>
        <div className="flex flex-col gap-y-4">
          <span className="text-sm font-semibold">Navigation</span>
          <ul className="flex flex-col gap-y-4">
            {HEADER_MENU.map(({ text, link }) => {
              return (
                <li
                  key={text}
                  className="font-light w-max text-white text-sm hover:text-gray-400"
                >
                  <Link href={link}>
                    <a style={{ color: 'white' }}>{text}</a>
                  </Link>
                </li>
              )
            })}
            <li
              key="manualentry"
              className="font-light w-max text-white text-sm hover:text-gray-400"
            >
              <Link href={'https://traqqie.mintlify.app/'} target="_blank">
                <a style={{ color: 'white' }}>Docs</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full h-[1px] bg-white opacity-10" />
      <div className="w-full flex flex-col gap-y-4">
        <Link href={PAGE.privacyPolicy}>
          <a className="text-white" style={{ color: 'white' }}>
            Privacy Policy
          </a>
        </Link>
        <Link href={PAGE.termsAndConditions}>
          <a className="text-white" style={{ color: 'white' }}>
            Terms and Conditions
          </a>
        </Link>
        <div className="text-white">
          Traqqie B.V.
          <br />
          Bankastraat 42, 1094EG Amsterdam <br />
          KVK: 96664606 <br />
          BTW: NL867705541B01 <br />
          Email: info@traqqie.com
        </div>
        <span className="text-white">
          © {new Date().getFullYear()} Traqqie.com. All Rights Reserved.
        </span>
      </div>
    </section>
  )
}

export default FooterV2
